"use strict";

export class Send {
    constructor (notification) {
        this.notification = notification;
    }

    /** Método que aplica al enviar una respuesta */
    sendAnswer() {
        document.querySelectorAll('.sendAnswer').forEach(button => {
            button.addEventListener('click', event => {
                /** Deshabilita el botón de envio */
                button.setAttribute('disabled', 'disabled');

                /** Agrega una clase al botón de envio */
                button.classList.add('cursor-not-allowed');

                let id = button.getAttribute("id");
                let attributes = id.split(".");
                let answer_id = attributes[1];
                let course_id = attributes[0];

                axios({
                    body: JSON.stringify({ course_id, answer_id }),
                    method: 'post',
                    url: `/evaluations/${course_id}/${answer_id}/resolve`
                }).then((response) => {
                    if (response.data === 0) {
                        this.notification.showToasted("alive", "error", `<p class='p-0'>Respuesta incorrecta</p>`);
                    } else {
                        this.notification.showToastedSpecial("alive", "success", `<p class='font-bold p-0 text-2xl'>Respuesta correcta</p>`);
                    }

                    setTimeout(() => location.reload());
                }).catch((error) => {
                    let message;

                    switch (error.response.status) {
                        case 400:
                            message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha enviado su respuesta, por favor vuelve a intentarlo.</p>';
                            break;

                        case 403:
                            message = '<p class="m-0">Acceso denegado.</p>';
                            break;

                        case 404:
                            message = '<p class="m-0">No se ha enviado su respuesta porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 405:
                            message = '<p class="m-0">No se ha enviado su respuesta porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;

                        case 500:
                            message = '<p class="m-0">No se ha enviado su respuesta porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 504:
                            message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha enviado su respuesta, por favor vuelve a intentarlo.</p>';
                            break;

                        case 509:
                            message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha enviado su respuesta, por favor vuelve a intentarlo.</p>';
                            break;

                        default:
                            message = '<p class="m-0">No se ha enviado su respuesta porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;
                    }

                    this.notification.showToasted("alive", "error", message);
                });
           });
       });
    }

    /** Método que se activa al enviar el formulario de importación */
    sendImport() {
        if (document.body.contains(document.querySelector('#formImport'))) {
            let form = document.querySelector('#formImport');
            let loader = document.querySelector('#loader');

            form.addEventListener('submit', event => {
                loader.classList.remove('hidden');
            })
        }
    }

    /** Método que aplica al registrar una nota */
    sendNote() {
        if (
            document.body.contains(document.querySelector('#btn-notes'))
            && document.body.contains(document.querySelector('#course_id'))
            && document.body.contains(document.querySelector('#description'))
            && document.body.contains(document.querySelector('#list_notes'))
        ) {
            let btnNotes = document.querySelector('#btn-notes');
            let course = document.querySelector('#course_id');
            let description = document.querySelector('#description');
            let listNotes = document.querySelector('#list_notes');

            btnNotes.addEventListener('click', event => {
                if (description.value == "") {
                    this.notification.showToasted("alive", "error", "Debe ingresar una nota");
                    return;
                }

                /** Deshabilita el botón de envio */
                btnNotes.disabled = true;
                btnNotes.classList.add('cursor-not-allowed');

                axios({
                    body: JSON.stringify({
                        course,
                        description
                    }),
                    method: 'post',
                    url: `/notes/${course.value}/${description.value}`
                }).then((response) => {
                    /** Limpia el campo de descripción */
                    description.value = null;

                    /** Crea el elemento */
                    let element = document.createElement('li');

                    /** Crea el texto */
                    let text = document.createTextNode(response.data);

                    /** Asigna las clases para el elemento */
                    element.classList.add("my-1", "ml-4", "text-xs", "text-justify", "text-white");

                    /** Coloca el texto en el elemento */
                    element.appendChild(text);

                    /** Coloca el elemento en la lista */
                    listNotes.appendChild(element);

                    /** Habilita el botón de envio */
                    btnNotes.disabled = false;
                    btnNotes.classList.remove('cursor-not-allowed');
                }).catch((error) => {

                    let message;

                    switch (error.response.status) {
                        case 400:
                            message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha registrado la nota, por favor vuelve a intentarlo.</p>';
                            break;

                        case 403:
                            message = '<p class="m-0">Acceso denegado.</p>';
                            break;

                        case 404:
                            message = '<p class="m-0">No se ha registrado la nota porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 405:
                            message = '<p class="m-0">No se ha registrado la nota porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;

                        case 500:
                            message = '<p class="m-0">No se ha registrado la nota porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 504:
                            message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha registrado la nota, por favor vuelve a intentarlo.</p>';
                            break;

                        case 509:
                            message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha registrado la nota, por favor vuelve a intentarlo.</p>';
                            break;

                        default:
                            message = '<p class="m-0">No se ha registrado la nota porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;
                    }

                    this.notification.showToasted("alive", "error", message);
                });
            })
        }
    }
}
