window._ = require("lodash");

import Axios from "axios";
import Chart from "./chart/chart.min.js";
import Selectr from "mobius1-selectr";
import Flowbite from "flowbite/dist/flowbite.js";
import Sortable from "./sortable/sortable.min.js";
import Swal from "sweetalert2";
import Toasted from "toastedjs";
import Ckeditor from "@ckeditor/ckeditor5-build-classic/build/ckeditor";

import { Display } from "./other/displays.js";
import { Elimination } from "./other/eliminations.js";
import { Generality } from "./other/generalities.js";
import { Graphic } from "./other/graphics.js";
import { Notification } from "./other/notifications.js";
import { Order } from "./other/orders.js";
import { Selection } from "./other/selections.js";
import { Send } from "./other/sends.js";
import { Show } from "./other/shows.js";

try {
    window.axios = Axios;
    window.Selectr = Selectr;
    window.Flowbite = Flowbite;
    window.Sortable = Sortable;
    window.Swal = Swal;
    window.Toasted = Toasted;
    window.Chart = Chart;
    window.Ckeditor = Ckeditor;

    const notification = new Notification();

    const display = new Display(notification);
    const elimination = new Elimination(notification);
    const generality = new Generality(notification);
    const graphic = new Graphic(notification);
    const order = new Order(notification);
    const selection = new Selection(notification);
    const send = new Send(notification);
    const show = new Show(notification);

    window.onload = () => {
        /** Métodos para mostrar/ocultar elementos */
        display.toggleMediaCourse();
        display.toggleMenuProfile();
        display.toggleMenuSidebar();
        display.toggleMenuTop();
        display.togglePasswordVisibility();

        /** Métodos para eliminar */
        elimination.destroyAnswer();
        elimination.destroyAttachment();
        elimination.destroyBranch();
        elimination.destroyCategory();
        elimination.destroyCompany();
        elimination.destroyCourse();
        elimination.destroyCourseMedia();
        elimination.destroyDepartment();
        elimination.destroyInscription();
        elimination.destroyNotification();
        elimination.destroyPoint();
        elimination.destroyQuestion();
        elimination.destroySection();
        elimination.destroySkill();
        elimination.destroyUser();

        /** Métodos generales */
        generality.activateCourse();
        generality.addAttachment();
        generality.addMedia();
        generality.addQuestion();
        generality.addStatement();
        generality.configCkeditor();
        generality.deactivateCourse();
        generality.disableForm();
        generality.removeAttachment();
        generality.removeMedia();
        generality.removeQuestion();
        generality.removeStatement();
        generality.selectConfig();

        /** Métodos para gráficos */
        graphic.renderPie();

        /** Métodos para ordenar */
        order.reorderAnswer();
        order.reorderAttachment();
        order.reorderCourseMedia();
        order.reorderPoint();
        order.reorderQuestion();
        order.reorderSection();
        order.reorderSkill();

        /** Métodos para selectores */
        selection.changeIcon();
        selection.chooseBranch();
        selection.chooseBranchGraphic();
        selection.chooseDepartments();
        selection.chooseGroups();
        selection.chooseRole();
        selection.chooseRoleTwo();
        selection.chooseUsers();

        /** Métodos para enviar información */
        send.sendAnswer();
        send.sendImport();
        send.sendNote();

        /** Métodos para marcar contenido terminado */
        show.showDepartments();
        show.showImage();
        show.showVideoLocal();
        show.showVideoRemote();
    };
} catch (e) {}

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
    console.error(
        "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
    );
}
