"use strict";

export class Display {
    constructor(notification) {
        this.notification = notification;
    }

    /** Método que muestra/oculta los módulos/notas */
    toggleMediaCourse() {
        if (
            document.body.contains(
                document.querySelector("#buttonAttachment")
            ) &&
            document.body.contains(
                document.querySelector("#buttonMediaCourse")
            ) &&
            document.body.contains(document.querySelector("#buttonNotes")) &&
            document.body.contains(document.querySelector("#divAttachments")) &&
            document.body.contains(
                document.querySelector("#divMediaCourses")
            ) &&
            document.body.contains(document.querySelector("#divNotes"))
        ) {
            let buttonAttachment = document.querySelector("#buttonAttachment");
            let buttonMediaCourse =
                document.querySelector("#buttonMediaCourse");
            let buttonNotes = document.querySelector("#buttonNotes");
            let divAttachments = document.querySelector("#divAttachments");
            let divMediaCourses = document.querySelector("#divMediaCourses");
            let divNotes = document.querySelector("#divNotes");

            buttonAttachment.addEventListener("click", (event) => {
                divMediaCourses.classList.add("hidden");
                divNotes.classList.add("hidden");
                divAttachments.classList.remove("hidden");
            });

            buttonMediaCourse.addEventListener("click", (event) => {
                divAttachments.classList.add("hidden");
                divNotes.classList.add("hidden");
                divMediaCourses.classList.remove("hidden");
            });

            buttonNotes.addEventListener("click", (event) => {
                divAttachments.classList.add("hidden");
                divMediaCourses.classList.add("hidden");
                divNotes.classList.remove("hidden");
            });
        }
    }

    /** Método que se encarga de mostrar/ocultar el submenú de perfil */
    toggleMenuProfile() {
        Array.from(document.getElementsByClassName("dropdown")).forEach((e) => {
            e.addEventListener("click", (a) => {
                if (a.currentTarget.classList.contains("navbar-item")) {
                    a.currentTarget.classList.toggle("active");
                } else {
                    a.currentTarget.parentNode.classList.toggle("active");
                }
            });
        });
    }

    /** Método que se encarga de mostrar/ocultar el menú sidebar en mobile */
    toggleMenuSidebar() {
        Array.from(
            document.getElementsByClassName("mobile-aside-button")
        ).forEach((e) => {
            e.addEventListener("click", (a) => {
                document.documentElement.classList.toggle(
                    "aside-mobile-expanded"
                );

                let element = a.currentTarget.getElementsByClassName("icon")[0];
                element.classList.toggle("fa-forward");
                element.classList.toggle("fa-backward");
            });
        });
    }

    /** Método que se encarga de mostrar/ocultar el menú de perfil en mobile */
    toggleMenuTop() {
        Array.from(
            document.getElementsByClassName("--jb-navbar-menu-toggle")
        ).forEach((e) => {
            e.addEventListener("click", (a) => {
                document
                    .getElementById(a.currentTarget.getAttribute("data-target"))
                    .classList.toggle("active");

                let element = a.currentTarget.getElementsByClassName("icon")[0];
                element.classList.toggle("fa-ellipsis-v");
                element.classList.toggle("fa-times");
            });
        });
    }

    /** Método que muestra la contraseña */
    togglePasswordVisibility() {
        document.querySelectorAll(".btn-password").forEach((item) => {
            let password = document.querySelector("#password");
            let passwordConfirmation = document.querySelector(
                "#password_confirmation"
            );

            item.addEventListener("click", (event) => {
                if (password.type == "text") {
                    item.classList.remove("fa-eye-slash");
                    item.classList.add("fa-eye");

                    password.type = "password";
                    passwordConfirmation.type = "password";
                } else {
                    item.classList.remove("fa-eye");
                    item.classList.add("fa-eye-slash");

                    password.type = "text";
                    passwordConfirmation.type = "text";
                }
            });
        });
    }
}
