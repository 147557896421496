"use strict";

export class Selection {
    constructor(notification) {
        this.notification = notification;
    }

    /** Método que aplica desplegar el contenido de una sección */
    changeIcon() {
        document.querySelectorAll(".changeIcon").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id") + "-icon";
                let span = document.querySelector(`#${id}`);

                if (span.classList.contains("fa-plus")) {
                    span.classList.remove("fa-plus");
                    span.classList.add("fa-minus");
                } else {
                    span.classList.remove("fa-minus");
                    span.classList.add("fa-plus");
                }
            });
        });
    }

    /** Método que aplica al tener un selector de sedes */
    chooseBranch() {
        if (
            document.body.contains(document.querySelector("#div_department")) &&
            document.body.contains(document.querySelector("#branch_id"))
        ) {
            let divDepartment = document.querySelector("#div_department");
            let branch = document.querySelector("#branch_id");

            try {
                new Selectr(branch);
            } catch (error) {
                console.log(error);
            }

            branch.addEventListener("change", (event) => {
                /** Valor de la sede elegida */
                let branch_value = branch.value;

                /** Ruta que retorna los grupos de la sede */
                let url = `/admin/departments/${branch_value}/get/active`;

                axios({
                    body: JSON.stringify({
                        branch_value,
                    }),
                    method: "put",
                    url: url,
                })
                    .then((response) => {
                        /** Muestra los grupos */
                        divDepartment.classList.remove("hidden");
                        divDepartment.innerHTML = response.data;

                        /** Campo selector del grupo */
                        let department =
                            document.querySelector("#department_id");

                        /** Obliga a elegir un grupo */
                        department.required = true;

                        try {
                            new Selectr(department);
                        } catch (error) {
                            console.log(error);
                        }
                    })
                    .catch((error) => {
                        let message;

                        switch (error.response.status) {
                            case 400:
                                message =
                                    '<p class="m-0">No se ha hecho la petición de forma correcta. No se pueden mostrar los grupos, por favor vuelve a intentarlo.</p>';
                                break;

                            case 403:
                                message = '<p class="m-0">Acceso denegado.</p>';
                                break;

                            case 404:
                                message =
                                    '<p class="m-0">No se pueden mostrar los grupos porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                break;

                            case 405:
                                message =
                                    '<p class="m-0">No se pueden mostrar los grupos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                break;

                            case 500:
                                message =
                                    '<p class="m-0">No se pueden mostrar los grupos porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                break;

                            case 504:
                                message =
                                    '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se pueden mostrar los grupos, por favor vuelve a intentarlo.</p>';
                                break;

                            case 509:
                                message =
                                    '<p class="m-0">Se ha superado el ancho de banda disponible. No se pueden mostrar los grupos, por favor vuelve a intentarlo.</p>';
                                break;

                            default:
                                message =
                                    '<p class="m-0">No se pueden mostrar los grupos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                break;
                        }

                        this.notification.showToasted(
                            "alive",
                            "error",
                            message
                        );
                    });
            });
        }
    }

    /** Método que aplica al tener un selector de sedes para renderizar un gráfico en pie */
    chooseBranchGraphic() {
        if (
            document.body.contains(
                document.querySelector("#branchContainer")
            ) &&
            document.body.contains(document.querySelector("#branch_id"))
        ) {
            let branch = document.querySelector("#branch_id");
            let department = document.querySelector("#department_id");
            let departmentContainer = document.querySelector(
                "#departmentContainer"
            );

            try {
                new Selectr(branch);
            } catch (error) {
                console.log(error);
            }

            branch.addEventListener("change", (event) => {
                /** Valor de la sede elegida */
                let branch_value = branch.value;

                /** Ruta que retorna los grupos de la sede */
                let url = `/admin/graphics/get/departments/${branch_value}`;

                axios({
                    body: JSON.stringify({
                        branch_value,
                    }),
                    method: "get",
                    url: url,
                })
                    .then((response) => {
                        /** Limpia el selector de departamentos */
                        department.innerHTML = "";

                        /** Agrega las opciones de departamentos */
                        for (let key in response.data) {
                            if (response.data.hasOwnProperty(key)) {
                                let option = document.createElement("option");

                                option.value = key;
                                option.text = response.data[key];
                                department.appendChild(option);
                            }
                        }

                        /** Muestra el selector de departamentos */
                        departmentContainer.classList.remove("hidden");
                    })
                    .catch((error) => {
                        let message;

                        switch (error.response.status) {
                            case 400:
                                message =
                                    '<p class="m-0">No se ha hecho la petición de forma correcta. No se pueden mostrar los grupos, por favor vuelve a intentarlo.</p>';
                                break;

                            case 403:
                                message = '<p class="m-0">Acceso denegado.</p>';
                                break;

                            case 404:
                                message =
                                    '<p class="m-0">No se pueden mostrar los grupos porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                break;

                            case 405:
                                message =
                                    '<p class="m-0">No se pueden mostrar los grupos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                break;

                            case 500:
                                message =
                                    '<p class="m-0">No se pueden mostrar los grupos porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                break;

                            case 504:
                                message =
                                    '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se pueden mostrar los grupos, por favor vuelve a intentarlo.</p>';
                                break;

                            case 509:
                                message =
                                    '<p class="m-0">Se ha superado el ancho de banda disponible. No se pueden mostrar los grupos, por favor vuelve a intentarlo.</p>';
                                break;

                            default:
                                message =
                                    '<p class="m-0">No se pueden mostrar los grupos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                break;
                        }

                        this.notification.showToasted(
                            "alive",
                            "error",
                            message
                        );
                    });
            });
        }
    }

    /** Método que aplica al seleccionar los grupos para inscripciones */
    chooseDepartments() {
        if (
            document.body.contains(document.querySelector("#table_departments"))
        ) {
            let formInscription = document.querySelector("#form_inscription");
            let inputDepartments = document.querySelector("#departments");
            let arraySelected = [];
            let countSelected = 0;

            /** Recorre todos los elementos que estan seleccionados */
            document.querySelectorAll(".on_inscription").forEach((item) => {
                /** Al dar click en el botón */
                item.addEventListener("change", (event) => {
                    /** Obtiene el valor */
                    let id = item.value;

                    /** Habilita/deshabilita ciertas clases para indicar en pantalla */
                    item.classList.toggle("marks");
                    item.classList.toggle("text-green-500");

                    /** Se agrega el id al arreglo */
                    if (
                        item.classList.contains("marks") &&
                        !arraySelected.includes(id)
                    ) {
                        arraySelected.push(id);
                        /** Se quita el id del arreglo */
                    } else {
                        arraySelected.pop(id);
                    }

                    /** Cantidad de elementos seleccionados */
                    countSelected = document.querySelectorAll(".marks").length;

                    /** Si la cantidad es menor o igual que 0 se oculta el formulario de envio */
                    if (countSelected <= 0) {
                        formInscription.classList.add("hidden");
                        formInscription.classList.remove("inline-flex");

                        /** de lo contrario se muestra */
                    } else {
                        formInscription.classList.add("inline-flex");
                        formInscription.classList.remove("hidden");
                    }

                    /** Asigna al valor oculto el arreglo de ids */
                    inputDepartments.value = arraySelected;
                });
            });
        }
    }

    /** Método que aplica al seleccionar grupos para el envio de correos */
    chooseGroups() {
        if (document.body.contains(document.querySelector("#table_groups"))) {
            let formGroups = document.querySelector("#form_groups");
            let inputGroups = document.querySelector("#groups");

            let arraySelected = [];
            let countSelected = 0;

            /** Recorre todos los elementos que estan seleccionados */
            document.querySelectorAll(".on_send").forEach((item) => {
                /** Al dar click en el botón */
                item.addEventListener("click", (event) => {
                    /** Obtiene el id del grupo */
                    let groupId = item.getAttribute("id");

                    /** Habilita/deshabilita ciertas clases para indicar en pantalla */
                    item.classList.toggle("mark_to_send");
                    item.classList.toggle("hover:bg-gray-400");
                    item.classList.toggle("hover:bg-green-400");
                    item.classList.toggle("bg-gray-400");
                    item.classList.toggle("bg-green-400");

                    /** Se agrega el id al arreglo */
                    if (
                        item.classList.contains("mark_to_send") &&
                        !arraySelected.includes(groupId)
                    ) {
                        arraySelected.push(groupId);

                        /** Se quita el id del arreglo */
                    } else {
                        arraySelected.pop(groupId);
                    }

                    /** Cantidad de elementos seleccionados */
                    countSelected =
                        document.querySelectorAll(".mark_to_send").length;

                    /** Si la cantidad es superior a 5 se muestra un mensaje */
                    if (countSelected > 20) {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>Por favor elija como máximo 5 grupos antes de continuar</p>"
                        );
                    }

                    /**
                     * 1. Si la cantidad es menor o igual que 0
                     * 2. Si la cantidad es mayor que 5
                     * se oculta el formulario de envio
                     */
                    if (countSelected <= 0 || countSelected > 5) {
                        formGroups.classList.add("hidden");
                        formGroups.classList.remove("inline-flex");

                        /** de lo contrario se muestra */
                    } else {
                        formGroups.classList.add("inline-flex");
                        formGroups.classList.remove("hidden");
                    }

                    /** Asigna al valor oculto el arreglo de ids */
                    inputGroups.value = arraySelected;
                });
            });
        }
    }

    /** Método que aplica al seleccionar un rol desde el crud de usuarios (super admin) */
    chooseRole() {
        if (
            document.body.contains(document.querySelector("#company_id")) &&
            document.body.contains(document.querySelector("#div_company")) &&
            document.body.contains(document.querySelector("#role_id"))
        ) {
            let company = document.querySelector("#company_id");
            let divCompany = document.querySelector("#div_company");
            let role = document.querySelector("#role_id");

            try {
                new Selectr(role);
            } catch (error) {
                console.log(error);
            }

            /** Se ejecuta al seleccionar un rol */
            role.addEventListener("change", (event) => {
                let role_value = role.value;

                if (role_value == 2) {
                    divCompany.classList.remove("hidden");
                    company.required = true;
                } else {
                    divCompany.classList.add("hidden");
                    company.required = false;
                }
            });
        }
    }

    /** Método que aplica al seleccionar un rol desde el crud de usuarios (admin) */
    chooseRoleTwo() {
        if (
            document.body.contains(
                document.querySelector("#div_identification")
            ) &&
            document.body.contains(
                document.querySelector("#div_password_confirmation")
            ) &&
            document.body.contains(document.querySelector("#div_password")) &&
            document.body.contains(document.querySelector("#div_phone")) &&
            document.body.contains(document.querySelector("#div_photo")) &&
            document.body.contains(document.querySelector("#role_id"))
        ) {
            let divBranch = document.querySelector("#div_branch");
            let divDepartment = document.querySelector("#div_department");
            let divIdentification = document.querySelector(
                "#div_identification"
            );
            let divPassword = document.querySelector("#div_password");
            let divPasswordConfirmation = document.querySelector(
                "#div_password_confirmation"
            );
            let divPhone = document.querySelector("#div_phone");
            let divPhoto = document.querySelector("#div_photo");

            let branch = document.querySelector("#branch_id");
            let role = document.querySelector("#role_id");
            let identification = document.querySelector("#identification");
            let password = document.querySelector("#password");
            let passwordConfirmation = document.querySelector(
                "#password_confirmation"
            );

            try {
                new Selectr(role);
            } catch (error) {
                console.log(error);
            }

            /** Se ejecuta al seleccionar un rol */
            role.addEventListener("change", (event) => {
                let role_value = role.value;

                if (role_value == 3) {
                    divBranch.classList.remove("hidden");
                    divIdentification.classList.add("hidden");
                    divPassword.classList.add("hidden");
                    divPasswordConfirmation.classList.add("hidden");
                    divPhone.classList.add("hidden");
                    divPhoto.classList.add("hidden");

                    branch.required = true;
                    identification.required = false;
                    password.required = false;
                    passwordConfirmation.required = false;
                } else {
                    divBranch.classList.add("hidden");
                    divIdentification.classList.remove("hidden");
                    divPassword.classList.remove("hidden");
                    divPasswordConfirmation.classList.remove("hidden");
                    divPhone.classList.remove("hidden");
                    divPhoto.classList.remove("hidden");

                    branch.required = false;
                    identification.required = true;
                    password.required = true;
                    passwordConfirmation.required = true;

                    /** Oculta el control del grupo */
                    divDepartment.classList.add("hidden");

                    /** Vuelve opcional el grupo */
                    if (
                        document.body.contains(
                            document.querySelector("#department_id")
                        )
                    ) {
                        document.querySelector(
                            "#department_id"
                        ).required = false;
                    }
                }
            });
        }
    }

    /** Método que aplica al seleccionar usuarios para el envio de correos */
    chooseUsers() {
        if (document.body.contains(document.querySelector("#table_users"))) {
            let formUsers = document.querySelector("#form_users");
            let inputUsers = document.querySelector("#users");

            let arraySelected = [];
            let countSelected = 0;

            /** Recorre todos los elementos que estan seleccionados */
            document.querySelectorAll(".on_send").forEach((item) => {
                /** Al dar click en el botón */
                item.addEventListener("click", (event) => {
                    /** Obtiene el id del usuario */
                    let userId = item.getAttribute("id");

                    /** Habilita/deshabilita ciertas clases para indicar en pantalla */
                    item.classList.toggle("mark_to_send");
                    item.classList.toggle("hover:bg-gray-400");
                    item.classList.toggle("hover:bg-green-400");
                    item.classList.toggle("bg-gray-400");
                    item.classList.toggle("bg-green-400");

                    /** Se agrega el id al arreglo */
                    if (
                        item.classList.contains("mark_to_send") &&
                        !arraySelected.includes(userId)
                    ) {
                        arraySelected.push(userId);

                        /** Se quita el id del arreglo */
                    } else {
                        arraySelected.pop(userId);
                    }

                    /** Cantidad de elementos seleccionados */
                    countSelected =
                        document.querySelectorAll(".mark_to_send").length;

                    /** Si la cantidad es superior a 20 se muestra un mensaje */
                    if (countSelected > 20) {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>Por favor elija como máximo 20 usuarios antes de continuar</p>"
                        );
                    }

                    /**
                     * 1. Si la cantidad es menor o igual que 0
                     * 2. Si la cantidad es mayor que 20
                     * se oculta el formulario de envio
                     */
                    if (countSelected <= 0 || countSelected > 20) {
                        formUsers.classList.add("hidden");
                        formUsers.classList.remove("inline-flex");

                        /** de lo contrario se muestra */
                    } else {
                        formUsers.classList.add("inline-flex");
                        formUsers.classList.remove("hidden");
                    }

                    /** Asigna al valor oculto el arreglo de ids */
                    inputUsers.value = arraySelected;
                });
            });
        }
    }
}
