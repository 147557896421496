"use strict";

export class Show {
    constructor (notification) {
        this.notification = notification;
    }

    /** Método que aplica al dar click al botón que muestra el modal con los grupos */
    showDepartments() {
        document.querySelectorAll('.showDepartments').forEach(item => {
            item.addEventListener('click', event => {
                let branch = item.getAttribute("id");
                let titleBranch = document.querySelector('#titleBranch');
                let url = `/admin/departments/${branch}/show`;

                axios({
                    method: 'put',
                    url: url,
                }).then(function(response) {
                    titleBranch.textContent = item.textContent;

                    let data = response.data;
                    let table =
                      '<div class="card has-table">' +
                        '<div class="overflow-auto card-content">' +
                          '<table>' +
                            '<tbody>';

                    table +=
                      '<tr class="flex">' +
                        '<th class="justify-start w-full">' +
                          'Código' +
                        '</th>' +
                        '<th class="justify-start w-full">' +
                          'Grupo' +
                        '</th>' +
                      '</tr>';

                    for (let key in data) {
                        let value = data[key];
                        table +=
                          '<tr class="flex">' +
                            '<td class="justify-start w-full">' + key + '</td>' +
                            '<td class="justify-start w-full">' + value + '</td>' +
                          '</tr>';
                    }

                    table +=
                            '</tbody>' +
                          '</table>' +
                        '</div>' +
                      '</div>';

                    document.getElementById('container').innerHTML = table;

                }).catch((error) => {
                    let message;

                    switch (error.response.status) {
                        case 400:
                            message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha podido mostrar los grupos, por favor vuelve a intentarlo.</p>';
                            break;

                        case 403:
                            message = '<p class="m-0">Acceso denegado.</p>';
                            break;

                        case 404:
                            message = '<p class="m-0">No se ha podido mostrar los grupos porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 405:
                            message = '<p class="m-0">No se ha podido mostrar los grupos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;

                        case 500:
                            message = '<p class="m-0">No se ha podido mostrar los grupos porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 504:
                            message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha podido mostrar los grupos, por favor vuelve a intentarlo.</p>';
                            break;

                        case 509:
                            message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha podido mostrar los grupos, por favor vuelve a intentarlo.</p>';
                            break;

                        default:
                            message = '<p class="m-0">No se ha podido mostrar los grupos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;
                    }

                    this.notification.showToasted("alive", "error", message);
                });
            });
        })
    }

    /** Método que aplica al ver una imagen */
    showImage() {
        document.querySelectorAll('.showImage').forEach(item => {
            let id = item.getAttribute("id");
            setTimeout(() => register(id), 500);

            function register(id) {
                axios({
                    body: JSON.stringify({ id }),
                    method: 'put',
                    url: `/courses/statistic/${id}`
                }).then((response) => {
                    let icon = document.querySelector(`#iconCircle_${id}`);

                    icon.classList.remove('far');
                    icon.classList.add('fas');
                });
            }
        })
    }

    /** Método que aplica al terminar de ver un video local */
    showVideoLocal() {
        document.querySelectorAll('.showVideoLocal').forEach(item => {
            item.addEventListener('ended', event => {
                let id = item.getAttribute("id");

                axios({
                    body: JSON.stringify({ id }),
                    method: 'put',
                    url: `/courses/statistic/${id}`
                }).then((response) => {
                    let icon = document.querySelector(`#iconCircle_${id}`);

                    icon.classList.remove('far');
                    icon.classList.add('fas');
                });
            })
        })
    }

    /** Método que aplica al terminar de ver un video local */
    showVideoRemote() {
        document.querySelectorAll('.showVideoRemote').forEach(item => {
            let id = item.getAttribute("id");
            setTimeout(() => register(id), 2000);

            function register(id) {
                axios({
                    body: JSON.stringify({ id }),
                    method: 'put',
                    url: `/courses/statistic/${id}`
                }).then((response) => {
                    let icon = document.querySelector(`#iconCircle_${id}`);

                    icon.classList.remove('far');
                    icon.classList.add('fas');
                });
            }
        })
    }
}
