"use strict";

export class Generality {
    constructor(notification) {
        this.notification = notification;
    }

    /** Método que aplica al activar un curso */
    activateCourse() {
        document.querySelectorAll(".activateCourse").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#008000",
                    "Sí, procede",
                    "<p class='m-0 text-center'></p>",
                    "success",
                    "<p class='m-0 text-center'>¿Realmente desea activar este curso?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id,
                            }),
                            method: "put",
                            url: `/admin/courses/${id}/activate`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () => (location.href = `/admin/courses`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha activado el curso, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha activado el curso porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha activado el curso porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha activado el curso porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha activado el curso, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha activado el curso, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha activado el curso porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>El curso seleccionado no ha sido activado.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Clona un nodo en el formulario de creación de anexos */
    addAttachment() {
        if (
            document.body.contains(document.querySelector("#buttonCreate")) &&
            document.body.contains(document.querySelector("#buttonDelete"))
        ) {
            let buttonCreate = document.querySelector("#buttonCreate");
            let buttonDelete = document.querySelector("#buttonDelete");

            /** Deshabilita el botón de eliminar nodo */
            buttonDelete.setAttribute("disabled", "disabled");

            /** Agrega una clase al botón de eliminar nodo */
            buttonDelete.classList.add("cursor-not-allowed");

            /** Al presionar el botón de añadir nodo */
            buttonCreate.addEventListener("click", (event) => {
                /** Contenedor principal */
                let box = document.querySelector("#box");

                this.addElement(
                    box,
                    ".cloneTitle",
                    "boxTitle",
                    "title",
                    buttonDelete,
                    buttonCreate
                );
                this.addElement(
                    box,
                    ".cloneAttachment",
                    "boxAttachment",
                    "attachment",
                    buttonDelete,
                    buttonCreate
                );
            });
        }
    }

    /** Método complementario para métodos que clonan elementos */
    addElement(main, classParent, idParent, key, btnRemove, btnAdd) {
        /** Cantidad de nodos clonados */
        let quantity = document.querySelectorAll(classParent).length;

        /** Genera el atributo id */
        let id = new Number(quantity + 1);

        /** Clona el nodo */
        let boxNode = document
            .querySelector(`#${idParent}` + quantity)
            .cloneNode(true);

        /** Asigna el id al nodo clonado */
        boxNode.id = idParent + id;

        /** Asigna clases al nodo clonado */
        boxNode.classList.add("my-2");

        /** Recupera el último elemento del nodo clonado */
        let sonNode = boxNode.lastElementChild;

        /** Agrega los atributos 'id', 'name', 'required' y 'value' al elemento */
        sonNode.id = key + id;
        sonNode.name = key + id;
        sonNode.value = "";
        sonNode.required = "true";

        /** Añade el nodo al contenedor principal */
        main.append(boxNode);

        /** Habilita el botón de eliminar nodo */
        btnRemove.removeAttribute("disabled");

        /** Quita una clase al botón de eliminar nodo */
        btnRemove.classList.remove("cursor-not-allowed");

        /** Si hay 10 nodos se deshabilita el botón de añadir nodo */
        if (id == 10) {
            btnAdd.classList.add("cursor-not-allowed");
            btnAdd.setAttribute("disabled", "disabled");
        }
    }

    /** Clona un nodo en el formulario de creación de contenidos */
    addMedia() {
        if (
            document.body.contains(document.querySelector("#btnAdd")) &&
            document.body.contains(document.querySelector("#btnRemove"))
        ) {
            let btnAdd = document.querySelector("#btnAdd");
            let btnRemove = document.querySelector("#btnRemove");

            /** Deshabilita el botón de eliminar nodo */
            btnRemove.setAttribute("disabled", "disabled");

            /** Agrega una clase al botón de eliminar nodo */
            btnRemove.classList.add("cursor-not-allowed");

            /** Al presionar el botón de añadir nodo */
            btnAdd.addEventListener("click", (event) => {
                /** Contenedor principal */
                let box = document.querySelector("#box");

                this.addElement(
                    box,
                    ".cloneTitle",
                    "boxTitle",
                    "title",
                    btnRemove,
                    btnAdd
                );
                this.addElement(
                    box,
                    ".cloneMedia",
                    "boxMedia",
                    "media",
                    btnRemove,
                    btnAdd
                );
            });
        }
    }

    /** Clona un nodo en el formulario de creación de preguntas */
    addQuestion() {
        if (
            document.body.contains(document.querySelector("#btnCreate")) &&
            document.body.contains(document.querySelector("#btnDelete"))
        ) {
            let btnCreate = document.querySelector("#btnCreate");
            let btnDelete = document.querySelector("#btnDelete");

            /** Deshabilita el botón de eliminar nodo */
            btnDelete.setAttribute("disabled", "disabled");

            /** Agrega una clase al botón de eliminar nodo */
            btnDelete.classList.add("cursor-not-allowed");

            /** Al presionar el botón de añadir nodo */
            btnCreate.addEventListener("click", (event) => {
                /** Contenedor principal */
                let box = document.querySelector("#box");

                this.addElement(
                    box,
                    ".cloneAnswer",
                    "boxAnswer",
                    "answer",
                    btnDelete,
                    btnCreate
                );
                this.addElement(
                    box,
                    ".cloneVeracity",
                    "boxVeracity",
                    "veracity",
                    btnDelete,
                    btnCreate
                );
            });
        }
    }

    /** Clona un nodo en el formulario de creación de habilidades/secciones */
    addStatement() {
        if (
            document.body.contains(document.querySelector("#buttonAdd")) &&
            document.body.contains(document.querySelector("#buttonRemove"))
        ) {
            let buttonAdd = document.querySelector("#buttonAdd");
            let buttonRemove = document.querySelector("#buttonRemove");

            /** Deshabilita el botón de eliminar nodo */
            buttonRemove.setAttribute("disabled", "disabled");

            /** Agrega una clase al botón de eliminar nodo */
            buttonRemove.classList.add("cursor-not-allowed");

            /** Al presionar el botón de añadir nodo */
            buttonAdd.addEventListener("click", (event) => {
                /** Contenedor principal */
                let box = document.querySelector("#box");

                /** Cantidad de nodos clonados */
                let quantity =
                    document.querySelectorAll(".cloneStatement").length;

                /** Genera el atributo id */
                let id = new Number(quantity + 1);

                /** Clona el nodo */
                let boxStatement = document
                    .querySelector("#boxStatement" + quantity)
                    .cloneNode(true);

                /** Asigna el id al div clonado */
                boxStatement.id = "boxStatement" + id;

                /** Recupera el input del div clonado */
                let inputboxStatement = boxStatement.firstElementChild;

                /** Agrega los atributos 'id', 'name', 'required' y 'value' al input */
                inputboxStatement.id = "statement" + id;
                inputboxStatement.name = "statement" + id;
                inputboxStatement.value = "";
                inputboxStatement.required = "true";

                /** Añade el nodo al contenedor principal */
                box.append(boxStatement);

                /** Habilita el botón de eliminar nodo */
                buttonRemove.removeAttribute("disabled");

                /** Quita una clase al botón de eliminar nodo */
                buttonRemove.classList.remove("cursor-not-allowed");

                /** Si hay 10 nodos se deshabilita el botón de añadir nodo */
                if (id == 10) {
                    buttonAdd.classList.add("cursor-not-allowed");
                    buttonAdd.setAttribute("disabled", "disabled");
                }
            });
        }
    }

    configCkeditor() {
        if (document.body.contains(document.querySelector(".wysiwyg"))) {
            Ckeditor.create(document.querySelector(".wysiwyg"), {
                removePlugins: [
                    "CKFinderUploadAdapter",
                    "CKFinder",
                    "EasyImage",
                    "Image",
                    "ImageCaption",
                    "ImageStyle",
                    "ImageToolbar",
                    "ImageUpload",
                    "MediaEmbed",
                ],
            }).catch((error) => {
                console.log(`error`, error);
            });
        }
    }

    /** Método que aplica al desactivar un curso */
    deactivateCourse() {
        document.querySelectorAll(".deactivateCourse").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'></p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea desactivar este curso?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id,
                            }),
                            method: "put",
                            url: `/admin/courses/${id}/deactivate`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () => (location.href = `/admin/courses`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha desactivado el curso, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha desactivado el curso porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha desactivado el curso porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha desactivado el curso porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha desactivado el curso, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha desactivado el curso, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha desactivado el curso porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>El curso seleccionado no ha sido desactivado.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que bloquea el botón 'submit' en un formulario */
    disableForm() {
        document.querySelectorAll(".form-main").forEach((form) => {
            form.addEventListener("submit", (event) => {
                document.querySelectorAll(".btn-form").forEach((button) => {
                    button.disabled = true;
                    button.classList.add("cursor-not-allowed");
                });
            });
        });
    }

    /** Quita un nodo en el formulario de creación de anexos */
    removeAttachment() {
        if (
            document.body.contains(document.querySelector("#buttonCreate")) &&
            document.body.contains(document.querySelector("#buttonDelete"))
        ) {
            let buttonCreate = document.querySelector("#buttonCreate");
            let buttonDelete = document.querySelector("#buttonDelete");

            /** Deshabilita el botón de eliminar nodo */
            buttonDelete.setAttribute("disabled", "disabled");

            /** Agrega una clase al botón de eliminar nodo */
            buttonDelete.classList.add("cursor-not-allowed");

            /** Al presionar el botón de eliminar nodo */
            buttonDelete.addEventListener("click", (event) => {
                this.removeElement(
                    ".cloneTitle",
                    "#boxTitle",
                    buttonCreate,
                    buttonDelete
                );
                this.removeElement(
                    ".cloneAttachment",
                    "#boxAttachment",
                    buttonCreate,
                    buttonDelete
                );
            });
        }
    }

    /** Método complementario para métodos que remueven elementos */
    removeElement(classParent, idParent, btnAdd, btnRemove) {
        /** Cantidad de nodos clonados */
        let quantity = document.querySelectorAll(classParent).length;

        /** Elimina el nodo */
        document.querySelector(idParent + quantity).remove();

        /** Habilita el botón de añadir nodo */
        btnAdd.removeAttribute("disabled");

        /** Quita una clase al botón de añadir nodo */
        btnAdd.classList.remove("cursor-not-allowed");

        /** Si la cantidad de nodos es uno deshabilita el botón de eliminar nodo */
        if (quantity - 1 == 1) {
            btnRemove.setAttribute("disabled", "disabled");

            /** Agrega una clase al botón de eliminar nodo */
            btnRemove.classList.add("cursor-not-allowed");
        }
    }

    /** Quita un nodo en el formulario de creación de contenidos */
    removeMedia() {
        if (
            document.body.contains(document.querySelector("#btnAdd")) &&
            document.body.contains(document.querySelector("#btnRemove"))
        ) {
            let btnAdd = document.querySelector("#btnAdd");
            let btnRemove = document.querySelector("#btnRemove");

            /** Deshabilita el botón de eliminar nodo */
            btnRemove.setAttribute("disabled", "disabled");

            /** Agrega una clase al botón de eliminar nodo */
            btnRemove.classList.add("cursor-not-allowed");

            /** Al presionar el botón de eliminar nodo */
            btnRemove.addEventListener("click", (event) => {
                this.removeElement(
                    ".cloneTitle",
                    "#boxTitle",
                    btnAdd,
                    btnRemove
                );
                this.removeElement(
                    ".cloneMedia",
                    "#boxMedia",
                    btnAdd,
                    btnRemove
                );
            });
        }
    }

    /** Quita un nodo en el formulario de creación de preguntas */
    removeQuestion() {
        if (
            document.body.contains(document.querySelector("#btnCreate")) &&
            document.body.contains(document.querySelector("#btnDelete"))
        ) {
            let btnCreate = document.querySelector("#btnCreate");
            let btnDelete = document.querySelector("#btnDelete");

            /** Deshabilita el botón de eliminar nodo */
            btnDelete.setAttribute("disabled", "disabled");

            /** Agrega una clase al botón de eliminar nodo */
            btnDelete.classList.add("cursor-not-allowed");

            /** Al presionar el botón de eliminar nodo */
            btnDelete.addEventListener("click", (event) => {
                this.removeElement(
                    ".cloneAnswer",
                    "#boxAnswer",
                    btnCreate,
                    btnDelete
                );
                this.removeElement(
                    ".cloneVeracity",
                    "#boxVeracity",
                    btnCreate,
                    btnDelete
                );
            });
        }
    }

    /** Quita un nodo en el formulario de creación de habilidades/secciones */
    removeStatement() {
        if (
            document.body.contains(document.querySelector("#buttonAdd")) &&
            document.body.contains(document.querySelector("#buttonRemove"))
        ) {
            let buttonAdd = document.querySelector("#buttonAdd");
            let buttonRemove = document.querySelector("#buttonRemove");

            /** Deshabilita el botón de eliminar nodo */
            buttonRemove.setAttribute("disabled", "disabled");

            /** Agrega una clase al botón de eliminar nodo */
            buttonRemove.classList.add("cursor-not-allowed");

            /** Al presionar el botón de eliminar nodo */
            buttonRemove.addEventListener("click", (event) => {
                this.removeElement(
                    ".cloneStatement",
                    "#boxStatement",
                    buttonAdd,
                    buttonRemove
                );
            });
        }
    }

    /** Método que aplica a elementos tipo 'select' */
    selectConfig() {
        document.querySelectorAll(".selectr").forEach((item) => {
            let id = item.getAttribute("id");

            try {
                new Selectr(document.getElementById(id));
            } catch (error) {
                console.log(error);
            }
        });
    }
}
