"use strict";

export class Graphic {
    constructor (notification) {
        this.notification = notification;
    }

    /** Método que aplica al enviar los datos para renderizar el gráfico en pie */
    renderPie() {
        if (
            document.body.contains(document.querySelector('#btnPie'))
            && document.body.contains(document.querySelector('#course_id'))
            && document.body.contains(document.querySelector('#department_id'))
            && document.body.contains(document.querySelector('#graphicChart'))
            && document.body.contains(document.querySelector('#resultText'))
            && document.body.contains(document.querySelector('#resultContainer'))
        ) {
            let btnPie = document.querySelector('#btnPie');
            let graphicChart = document.querySelector('#graphicChart');
            let course = document.querySelector('#course_id');
            let department = document.querySelector('#department_id');
            let resultContainer = document.querySelector('#resultContainer');
            let resultText = document.querySelector('#resultText');

            btnPie.addEventListener('click', event => {
                if (course.value == "" || department.value == "") {
                    this.notification.showToasted("alive", "error", "Debe elegir el curso y el grupo");
                    return;
                }

                axios({
                    body: JSON.stringify({
                        course,
                        department
                    }),
                    method: 'get',
                    url: `/admin/graphics/get/user/complete/${course.value}/${department.value}`
                }).then((response) => {
                    let completedCount = response.data.completedCount;
                    let notCompletedCount = response.data.notCompletedCount;
                    let userCount = response.data.userCount;

                    /** Muestra el número de usuarios inscritos */
                    resultText.innerHTML = 'Número de Usuarios Inscritos:' + userCount;
                    resultContainer.classList.remove('hidden');

                    /** Actualiza el gráfico */
                    new Chart(graphicChart, {
                        type: 'pie',
                        data: {
                        labels: ['Completado', 'No Completado'],
                        datasets: [{
                            data: [completedCount, notCompletedCount],
                            backgroundColor: ['#3498db', '#e74c3c'],
                        }],
                        },
                    });
                }).catch((error) => {

                    let message;

                    switch (error.response.status) {
                        case 400:
                            message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha podido renderizar el gráfico, por favor vuelve a intentarlo.</p>';
                            break;

                        case 403:
                            message = '<p class="m-0">Acceso denegado.</p>';
                            break;

                        case 404:
                            message = '<p class="m-0">No se ha podido renderizar el gráfico porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 405:
                            message = '<p class="m-0">No se ha podido renderizar el gráfico porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;

                        case 500:
                            message = '<p class="m-0">No se ha podido renderizar el gráfico porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 504:
                            message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha podido renderizar el gráfico, por favor vuelve a intentarlo.</p>';
                            break;

                        case 509:
                            message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha podido renderizar el gráfico, por favor vuelve a intentarlo.</p>';
                            break;

                        default:
                            message = '<p class="m-0">No se ha podido renderizar el gráfico porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;
                    }

                    this.notification.showToasted("alive", "error", message);
                });
            })
        }
    }
}
