"use strict";

export class Elimination {
    constructor(notification) {
        this.notification = notification;
    }

    /** Método que aplica al eliminar una respuesta */
    destroyAnswer() {
        document.querySelectorAll(".destroyAnswer").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let attributes = id.split(".");
                let answer_id = attributes[1];
                let question_id = attributes[0];

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar esta respuesta?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ question_id, answer_id }),
                            method: "delete",
                            url: `/admin/answers/${question_id}/${answer_id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () =>
                                        (location.href = `/admin/answers/${question_id}`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la respuesta, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado la respuesta porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado la respuesta porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado la respuesta porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la respuesta, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la respuesta, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado la respuesta porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>La respuesta seleccionada no ha sido afectada.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar un anexo */
    destroyAttachment() {
        document.querySelectorAll(".destroyAttachment").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let attributes = id.split(".");
                let section_id = attributes[0];
                let attachment_id = attributes[1];

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar este anexo?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                section_id,
                                attachment_id,
                            }),
                            method: "delete",
                            url: `/admin/attachments/${section_id}/${attachment_id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(() => location.reload());
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el anexo, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado el anexo porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado el anexo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado el anexo porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el anexo, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el anexo, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado el anexo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>El anexo seleccionado no ha sido afectado.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar una sede */
    destroyBranch() {
        document.querySelectorAll(".destroyBranch").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let attributes = id.split(".");
                let branch_id = attributes[1];
                let company_id = attributes[0];

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar esta sede?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ company_id, branch_id }),
                            method: "delete",
                            url: `/admin/branches/${company_id}/${branch_id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () =>
                                        (location.href = `/admin/branches/${company_id}`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la sede, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado la sede porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado la sede porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado la sede porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la sede, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la sede, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado la sede porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>La sede seleccionada no ha sido afectada.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar una categoría */
    destroyCategory() {
        document.querySelectorAll(".destroyCategory").forEach((item) => {
            item.addEventListener("click", (event) => {
                let category_id = item.getAttribute("id");

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar esta categoría?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ category_id }),
                            method: "delete",
                            url: `/admin/categories/${category_id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () => (location.href = `/admin/categories`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la categoría, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado la categoría porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado la categoría porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado la categoría porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la categoría, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la categoría, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado la categoría porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>La categoría seleccionada no ha sido afectada.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar una empresa */
    destroyCompany() {
        document.querySelectorAll(".destroyCompany").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar esta empresa?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id,
                            }),
                            method: "delete",
                            url: `/admin/companies/${id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () => (location.href = `/admin/companies`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la empresa, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado la empresa porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado la empresa porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado la empresa porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la empresa, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la empresa, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado la empresa porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>La empresa seleccionada no ha sido afectada.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar un curso */
    destroyCourse() {
        document.querySelectorAll(".destroyCourse").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar este curso?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id,
                            }),
                            method: "delete",
                            url: `/admin/courses/${id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () => (location.href = `/admin/courses`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el curso, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado el curso porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado el curso porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado el curso porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el curso, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el curso, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado el curso porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>El curso seleccionado no ha sido afectado.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar un contenido */
    destroyCourseMedia() {
        document.querySelectorAll(".destroyCourseMedia").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let attributes = id.split(".");
                let section_id = attributes[0];
                let course_media_id = attributes[1];

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar este contenido?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                section_id,
                                course_media_id,
                            }),
                            method: "delete",
                            url: `/admin/course_medias/${section_id}/${course_media_id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(() => location.reload());
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el contenido, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado el contenido porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado el contenido porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado el contenido porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el contenido, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el contenido, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado el contenido porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>El contenido seleccionado no ha sido afectado.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar un grupo */
    destroyDepartment() {
        document.querySelectorAll(".destroyDepartment").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let attributes = id.split(".");
                let branch_id = attributes[0];
                let department_id = attributes[1];

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar este grupo?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ branch_id, department_id }),
                            method: "delete",
                            url: `/admin/departments/${branch_id}/${department_id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () =>
                                        (location.href = `/admin/departments/${branch_id}`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el grupo, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado el grupo porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado el grupo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado el grupo porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el grupo, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el grupo, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado el grupo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>El grupo seleccionado no ha sido afectado.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar una inscripción */
    destroyInscription() {
        document.querySelectorAll(".destroyInscription").forEach((item) => {
            item.addEventListener("click", (event) => {
                let inscription_id = item.getAttribute("id");

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar esta inscripción?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ inscription_id }),
                            method: "delete",
                            url: `/admin/inscriptions/${inscription_id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () =>
                                        (location.href = `/admin/inscriptions`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la inscripción, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado la inscripción porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado la inscripción porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado la inscripción porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la inscripción, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la inscripción, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado la inscripción porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>La inscripción seleccionada no ha sido afectada.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar una notificación */
    destroyNotification() {
        document.querySelectorAll(".destroyNotification").forEach((item) => {
            item.addEventListener("click", (event) => {
                let notification_id = item.getAttribute("id");

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar esta notificación?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ notification_id }),
                            method: "delete",
                            url: `/admin/notifications/${notification_id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () =>
                                        (location.href = `/admin/notifications`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la notificación, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado la notificación porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado la notificación porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado la notificación porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la notificación, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la notificación, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado la notificación porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>La notificación seleccionada no ha sido afectada.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar una pregunta frecuente */
    destroyPoint() {
        document.querySelectorAll(".destroyPoint").forEach((item) => {
            item.addEventListener("click", (event) => {
                let point_id = item.getAttribute("id");

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar esta pregunta?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ point_id }),
                            method: "delete",
                            url: `/admin/points/${point_id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () => (location.href = `/admin/points`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la pregunta frecuente, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado la pregunta frecuente porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado la pregunta frecuente porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado la pregunta frecuente porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la pregunta frecuente, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la pregunta frecuente, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado la pregunta frecuente porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>La pregunta frecuente seleccionada no ha sido afectada.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar una pregunta */
    destroyQuestion() {
        document.querySelectorAll(".destroyQuestion").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let attributes = id.split(".");
                let evaluation_id = attributes[0];
                let question_id = attributes[1];

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar esta pregunta?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                evaluation_id,
                                question_id,
                            }),
                            method: "delete",
                            url: `/admin/questions/${evaluation_id}/${question_id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(() => location.reload());
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la pregunta, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado la pregunta porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado la pregunta porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado la pregunta porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la pregunta, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la pregunta, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado la pregunta porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>La pregunta seleccionada no ha sido afectada.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar una sección */
    destroySection() {
        document.querySelectorAll(".destroySection").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let attributes = id.split(".");
                let course_id = attributes[0];
                let section_id = attributes[1];

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar esta sección?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ course_id, section_id }),
                            method: "delete",
                            url: `/admin/sections/${course_id}/${section_id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(() => location.reload());
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la sección, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado la sección porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado la sección porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado la sección porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la sección, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la sección, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado la sección porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>La sección seleccionada no ha sido afectada.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar una habilidad */
    destroySkill() {
        document.querySelectorAll(".destroySkill").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let attributes = id.split(".");
                let course_id = attributes[0];
                let skill_id = attributes[1];

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar esta habilidad?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ course_id, skill_id }),
                            method: "delete",
                            url: `/admin/skills/${course_id}/${skill_id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(() => location.reload());
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la habilidad, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado la habilidad porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado la habilidad porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado la habilidad porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la habilidad, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la habilidad, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado la habilidad porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>La habilidad seleccionada no ha sido afectada.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica al eliminar un usuario */
    destroyUser() {
        document.querySelectorAll(".destroyUser").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");

                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#E3051B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Esta acción es irreversible.</p>",
                    "warning",
                    "<p class='m-0 text-center'>¿Realmente desea eliminar este usuario?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ id }),
                            method: "delete",
                            url: `/admin/users/${id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () => (location.href = `/admin/users`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el usuario, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha eliminado el usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha eliminado el usuario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha eliminado el usuario porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el usuario, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el usuario, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha eliminado el usuario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>El usuario seleccionado no ha sido afectado.</p>"
                        );
                    }
                });
            });
        });
    }
}
