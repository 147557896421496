"use strict";

export class Order {
    constructor(notification) {
        this.notification = notification;
    }

    /** Método que aplica al reordenar la lista de respuestas de una pregunta */
    reorderAnswer() {
        if (
            document.body.contains(document.querySelector("#table-answers")) &&
            document.body.contains(document.querySelector("#simple-list"))
        ) {
            let list = document.querySelector("#simple-list");
            let question = document.getElementsByClassName("question_id")[0].id;

            Sortable.create(list, {
                animation: 150,
                ghostClass: "bg-blue-200",
                store: {
                    set: (sortable) => {
                        let sorts = sortable.toArray();
                        let url = `/admin/answers/${question}/${sorts}/reorder`;

                        axios({
                            body: JSON.stringify({
                                sorts,
                                question,
                            }),
                            method: "put",
                            url: url,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(() => location.reload());
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se han organizado las respuestas, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se han organizado las respuestas porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se han organizado las respuestas porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se han organizado las respuestas porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han organizado las respuestas, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se han organizado las respuestas, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se han organizado las respuestas porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    },
                },
            });
        }
    }

    /** Método que aplica al reordenar la lista de anexos de una sección */
    reorderAttachment() {
        if (
            document.body.contains(
                document.querySelector("#table-attachments")
            ) &&
            document.body.contains(document.querySelector("#simple-list"))
        ) {
            let list = document.querySelector("#simple-list");
            let section = document.getElementsByClassName("section_id")[0].id;

            Sortable.create(list, {
                animation: 150,
                ghostClass: "bg-blue-200",
                store: {
                    set: (sortable) => {
                        let sorts = sortable.toArray();
                        let url = `/admin/attachments/${section}/${sorts}/reorder`;

                        axios({
                            body: JSON.stringify({
                                sorts,
                                section,
                            }),
                            method: "put",
                            url: url,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(() => location.reload());
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se han organizado los anexos, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se han organizado los anexos porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se han organizado los anexos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se han organizado los anexos porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han organizado los anexos, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se han organizado los anexos, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se han organizado los anexos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    },
                },
            });
        }
    }

    /** Método que aplica al reordenar la lista de contenidos de una sección */
    reorderCourseMedia() {
        if (
            document.body.contains(
                document.querySelector("#table-course-medias")
            ) &&
            document.body.contains(document.querySelector("#simple-list"))
        ) {
            let list = document.querySelector("#simple-list");
            let section = document.getElementsByClassName("section_id")[0].id;

            Sortable.create(list, {
                animation: 150,
                ghostClass: "bg-blue-200",
                store: {
                    set: (sortable) => {
                        let sorts = sortable.toArray();
                        let url = `/admin/course_medias/${section}/${sorts}/reorder`;

                        axios({
                            body: JSON.stringify({
                                sorts,
                                section,
                            }),
                            method: "put",
                            url: url,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(() => location.reload());
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se han organizado los contenidos, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se han organizado los contenidos porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se han organizado los contenidos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se han organizado los contenidos porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han organizado los contenidos, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se han organizado los contenidos, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se han organizado los contenidos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    },
                },
            });
        }
    }

    /** Método que aplica al reordenar la lista de preguntas frecuentes */
    reorderPoint() {
        if (
            document.body.contains(document.querySelector("#table-points")) &&
            document.body.contains(document.querySelector("#simple-list"))
        ) {
            let list = document.querySelector("#simple-list");

            Sortable.create(list, {
                animation: 150,
                ghostClass: "bg-blue-200",
                store: {
                    set: (sortable) => {
                        let sorts = sortable.toArray();
                        let url = `/admin/points/${sorts}/reorder`;

                        axios({
                            body: JSON.stringify({
                                sorts,
                            }),
                            method: "put",
                            url: url,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(() => location.reload());
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se han organizado las preguntas frecuentes, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se han organizado las preguntas frecuentes porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se han organizado las preguntas frecuentes porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se han organizado las preguntas frecuentes porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han organizado las preguntas frecuentes, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se han organizado las preguntas frecuentes, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se han organizado las preguntas frecuentes porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    },
                },
            });
        }
    }

    /** Método que aplica al reordenar la lista de preguntas de una evaluación */
    reorderQuestion() {
        if (
            document.body.contains(
                document.querySelector("#table-questions")
            ) &&
            document.body.contains(document.querySelector("#simple-list"))
        ) {
            let list = document.querySelector("#simple-list");
            let evaluation =
                document.getElementsByClassName("evaluation_id")[0].id;

            Sortable.create(list, {
                animation: 150,
                ghostClass: "bg-blue-200",
                store: {
                    set: (sortable) => {
                        let sorts = sortable.toArray();
                        let url = `/admin/questions/${evaluation}/${sorts}/reorder`;

                        axios({
                            body: JSON.stringify({
                                sorts,
                                evaluation,
                            }),
                            method: "put",
                            url: url,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(() => location.reload());
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se han organizado las preguntas, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se han organizado las preguntas porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se han organizado las preguntas porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se han organizado las preguntas porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han organizado las preguntas, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se han organizado las preguntas, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se han organizado las preguntas porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    },
                },
            });
        }
    }

    /** Método que aplica al reordenar la lista de secciones de un curso */
    reorderSection() {
        if (
            document.body.contains(document.querySelector("#table-sections")) &&
            document.body.contains(document.querySelector("#simple-list"))
        ) {
            let list = document.querySelector("#simple-list");
            let course = document.getElementsByClassName("course_id")[0].id;

            Sortable.create(list, {
                animation: 150,
                ghostClass: "bg-blue-200",
                store: {
                    set: (sortable) => {
                        let sorts = sortable.toArray();
                        let url = `/admin/sections/${course}/${sorts}/reorder`;

                        axios({
                            body: JSON.stringify({
                                sorts,
                                course,
                            }),
                            method: "put",
                            url: url,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(() => location.reload());
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se han organizado las secciones, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se han organizado las secciones porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se han organizado las secciones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se han organizado las secciones porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han organizado las secciones, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se han organizado las secciones, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se han organizado las secciones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    },
                },
            });
        }
    }

    /** Método que aplica al reordenar la lista de habilidades de un curso */
    reorderSkill() {
        if (
            document.body.contains(document.querySelector("#table-skills")) &&
            document.body.contains(document.querySelector("#simple-list"))
        ) {
            let list = document.querySelector("#simple-list");
            let course = document.getElementsByClassName("course_id")[0].id;

            Sortable.create(list, {
                animation: 150,
                ghostClass: "bg-blue-200",
                store: {
                    set: (sortable) => {
                        let sorts = sortable.toArray();
                        let url = `/admin/skills/${course}/${sorts}/reorder`;

                        axios({
                            body: JSON.stringify({
                                sorts,
                                course,
                            }),
                            method: "put",
                            url: url,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(() => location.reload());
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se han organizado las habilidades, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se han organizado las habilidades porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se han organizado las habilidades porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se han organizado las habilidades porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han organizado las habilidades, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se han organizado las habilidades, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se han organizado las habilidades porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    },
                },
            });
        }
    }
}
